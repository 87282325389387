
  .loading, .error {
    text-align: center;
    margin-top: 20px;
  }
  
  .cardContainer {

    display: flex;
    flex-direction: column;
    gap: 2px;
    /* max-width: 100%; */
  }
  
  .cardShowaddress {
    background: linear-gradient(90deg, #FF5733, #C70039);
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 6px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.4);
    transition: transform 0.2s;
    margin-top: 5px;
  }
  
  .card:hover {
    transform: scale(1.02);
  }
  
  .cardHeader {
    margin-bottom: 2px;
  }
  
  .cardBody p {
    margin: 2px 0;
  }
  