.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 10%;
  background-image: linear-gradient(135deg, #29e6c9, #0066ff); 
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  z-index: 1000;
}

.footerItem {
  flex: 1;
  text-align: center;
  padding: 10px 0;
  cursor: pointer;
}

.footerItem:hover {
  color: #b7eefd; 
}

.footerItem h6 {
  margin-top: 5px;
}

.activeTint {
  background-color: rgba(255, 255, 255, 0.5); 
  border-radius: 10px; 
}
.notification-badge-message {
  position: absolute;
  top: 3px;

  right: 90px;
  background-color: red;
  color: white;
  padding: 2px 6px;
  border-radius: 50%;
  font-size: 12px;
}

@media (max-width: 767px) {
  .footerItem {
    flex: 1 0 20%; 
  }
}
