.container {
    /* padding: 16px; */
    background-color: #f9f9f9;
    margin-top: 50px;
}

.balance {
    font-size: 22px;
    font-weight: bold;
    margin-bottom: 16px;
}

.scrollView {
    max-height: 460px; /* You can adjust this height as needed */
    overflow-y: scroll;
}

.cardTrans {
    background-color: #fff;
    padding: 10px;
    margin: 8px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
.cardTrans1{
    background-color: #fff;
    width: 45vh;
    padding: 10px;
    margin: 8px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.transactionType {
    font-size: 18px;
    font-weight: bold;
}

.transactionDetail {
    font-size: 10px;
    margin: 4px 0;
}
.balance-section1{
    height: 10vh;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    background-color: #e0e0e0;
    padding: 10px 20px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.5);
    margin-top: 5px;
}

.transaction-deposit1{
    height: 85vh;
    width: 100%;
    /* width: 50vh; */
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    /* background-color: #e0e0e0; */
    padding: 10px 10px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.5);
    margin-top: 5px;
    border-radius: 10px;
}
.deposit {
    background-color: #cce5ff; /* Light blue for deposits */
  }
  
  .withdrawal {
    background-color: #f8d7da; /* Light red for withdrawals */
  }

  .team-report-container {
    text-align: center;
    margin-top: 65px;
    background-color: #fff;
  }
  .team-container-de {
    max-width: 370px;
    max-height: 80vh; /* Sets the height to 80% of the viewport height */
    margin: 1rem;
    padding: 0.5rem;
    border: 1px solid #ddd;
    border-radius: 15px;
    justify-content: center;
    background-color: #f0f0f0;
    box-shadow: 0px 4px 12px rgba(64, 123, 250, 0.5);
    overflow-y: auto; /* Enables scrolling if content exceeds max-height */
}

    