.containerFinance {
  /* display: flex; */
  flex-direction: column;
  height: 100vh;
}

.yellow-section {
  /* flex: 0.3; */
  background-color: yellow;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 11%;
}

.gray-section {
  flex: 0.7;
  background-color: #f0f0f0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 96%;
}
.card1 {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
}

.card2 {
  display: flex;
  justify-content: space-between;
  margin: 5px 0;
}

.sub-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #fff;
  text-align: center;
  flex: 1;
  margin: 0 5px;
}
.sub-card2 {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #fff;
  text-align: center;
  /* flex: 1; */
  margin: 0 5px;
}
.sub-card span:first-child {
  font-weight: bold;
  margin-bottom: 10px;
}

.sub-card span:last-child {
  font-size: 1.2em;
  color: #000;
}

.input-box {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.button-section {
  display: flex;
  /* justify-content: space-around; */
  margin: 20px 0;
  padding: 0 20px;
  gap:10px;
}

.transfer-button {
  padding: 10px 20px;
  font-size: 1em;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  /* margin: 10; */
}

.transfer-button:hover {
  background-color: #005
}

/* .container {
    display: flex;
    flex-direction: column;
    height: 100vh;
  }
  
  .gray-section {
    flex: 0.3;
    background-color: gray;

    flex-direction: row;
    display: flex;
  }
  
  .yellow-section {
    flex: 0.7;
    background-color: yellow;

  }
  

  
  .yellow-section {
    background-color: yellow;
    height: 30%;
    display: flex;
    justify-content: center;
    align-items: center;

  }
  
  .gray-section {
    background-color: #f0f0f0;
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 70%;
  }
  
  .card {
    display: flex;
    justify-content: space-between;
    width: 96%;

  }
  .card2 {
flex-direction: row;
     display: flex;

   margin: 5;

  }
  
  .sub-card {
    display: flex;
    flex-direction: column;
    align-items: center;

    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #fff;

    margin: 0 0px;
    text-align: center;
    width: 40%;
  }
  
  .sub-card span:first-child {
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .sub-card span:last-child {
    font-size: 1.2em;
    color: #000;
  }
  
  .input-box {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
   */