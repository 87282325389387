
.position-relative {
  position: relative;
}

.position-absolute {
  position: absolute;
}

.top-50 {
  top: 50%;
}

.start-0 {
  left: 0;
}

.translate-middle-y {
  transform: translateY(-50%);
}

.ms-2 {
  margin-left: 0.5rem;
}

.ms-5 {
  margin-left: 3rem;
}

.btn-primary {
  background-color: #007bff;
  border: none;
  padding: 10px 20px;
}

.btn-primary:hover {
  background-color: #0056b3;
}
