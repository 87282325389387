
.slider-container {
    width: 100%;
    overflow: hidden;
    margin: 0 auto;
  }

  .main__sLideR img {
    aspect-ratio: 2/1;
    object-fit: cover;
    border-radius:0px !important;
}
  
  .slider-image {
    width: 100%;
    height: auto;
    border-radius: 5px;
  }
  