.company-profile {
    /* padding: 20px; */
    max-width: 800px;
    margin: auto;
    font-family: Arial, sans-serif;
  }
  
  h1, h2 {
    color: #333;
    margin-bottom: 20px;
  }
  
  .card {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    margin-bottom: 20px;
    margin-top: 10%;
    margin-left: 8px;
  }
  
  .tier-section {
    margin-bottom: 20px;
  }
  
  .tier-card {
    margin-bottom: 20px;
    padding: 15px;
    border: 1px solid #ddd;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .tier-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
  }
  
  .tier-header h3 {
    font-size: 1.2em;
    margin: 0;
  }
  
  .tier-header p {
    font-size: 0.9em;
    color: #888;
    text-align: right;
  }
  
  .tier-description {
    text-align: right;
    color: #555;
  }
  
  .why-choose-section p {
    text-align: justify;
    line-height: 1.6;
    color: #555;

    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  