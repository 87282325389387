.header {
    display: flex;
    align-items: center; 
    /* justify-content: space-between; Space out the items */
    /* padding: 10px 20px; Add padding around the header */
    background-color: #f8f8f8; /* Background color of the header */
    border-bottom: 1px solid #ddd; /* Optional: Border for visual separation */
  }
  
  .flag-btn,
  .notification-btn {
    background: none;
    border: none;
    font-size: 24px; /* Size of the icons */
    cursor: pointer;
    color: #333; /* Color of the icons */
    width: 55px;
    padding: 8px;
  }
  
  .header-title {
    flex: 1; /* Takes up the remaining space */
    text-align: center; /* Center text horizontally */
    font-size: 18px; /* Size of the header text */
    margin: 0; /* Remove default margin */
    color: #333; /* Color of the header text */
  }
  .dropdown-container1,countries{
    background: none;
    border: none;
    font-size: 24px; /* Size of the icons */
    cursor: pointer;
    color: #333; /* Color of the icons */
    /* width: 40px; */

  }
  .notification-badge {
    position: absolute;
    top: 3px;
    right: 3px;
    background-color: red;
    color: white;
    padding: 2px 6px;
    border-radius: 50%;
    font-size: 12px;
  }
  