
.home-container {
    text-align: center;
    margin-top: 60px;
  }
  
  .nav-links {
    list-style-type: none;
    padding: 0;
  }
  
  .nav-links li {
    display: inline;
    margin: 0 1rem;
  }

  .screenMiddle {
    padding: 1%; 
    margin-top: 0.8%;
    width: 98%; 
    align-self: center;
    border-radius: 1%; 
    margin: 0.5%; 
  }
  
  .text {
    color: #ffffff; 
    font-size: 20px; 
    margin-left: 0.5%; 
    margin-bottom: 0%;
  }
  
  .text1 {
    color: #ffffff; 
    font-size: 20px; 
    margin-top: 2.5%;
  }
  
  .buttonRow {
    display: flex;
    flex-direction: row;
    margin-top: 1%; 
    margin-left: 0.8%; 
    margin-bottom:10px;
  }
  
  .buttonRow1 {
    display: flex;
    flex-direction: row;
    margin-top: 1%;
    margin-left: 0.8%; 
    justify-content: center;
    margin-bottom:10px;
  }
  
  .buttonRow2 {
    display: flex;
    justify-content: center;
    margin-top: 1%; 
  }
  
  button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1%;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    border: 0.2% solid #000;
    border-radius: 20; 
  }
  
  button:hover {
    background-color: #f0f0f0;
    border-color: #EC8E22;
  }
  
  .direct {
    height: 5%; 
    width: 40%; 
    background: linear-gradient(135deg, #29e6c9, #0066ff);
    margin-right: 1%; 
    border-radius: 8px;
    border:none;
    cursor: "pointer";
    transition: 'background 0.3s ease';
     box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.3);
    
  }

  @media screen and (max-width: 575px) {
    .direct {
        width: 50% !important;
        /* padding: 0.8em !important; */
    }
    .text {
        margin-left: 4.5%;
        font-size: 15px;
    }
    .text1 {
        color: #ffffff;
        font-size: 12px;
        margin-top: 2.5%;
    }
  }
  
  .direct1 {
    display: flex;
    flex-direction: column; /* Align items vertically */
    align-items: center; /* Center align the icon and text */
    justify-content: center;
    height: 7%; /* Converted height to percentage */
    width: 19%; /* Already in percentage */
    margin-right: 1.5%; /* Converted margin-right to percentage */
     border-radius: 8px;
    border:none;
    cursor: "pointer";
    transition: 'background 0.1s ease';
     box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2);
  }
  
  /* Unique gradient backgrounds for each button */
  .direct1:nth-of-type(1) {
    background: linear-gradient(135deg, #ff7e5f, #feb47b); /* Gradient for Financial */
  }
  
  .direct1:nth-of-type(2) {
    background: linear-gradient(135deg, #6a11cb, #2575fc); /* Gradient for Promotion Reward */
  }
  
  .direct1:nth-of-type(3) {
    background: linear-gradient(135deg, #07686f, #55d9e3); /* Gradient for Company Profile */
  }
  
  .direct1:nth-of-type(4) {
    background: linear-gradient(135deg, #ff6f61, #de6a9e); /* Gradient for VIP Events */
  }
  
  .direct1:nth-of-type(5) {
    background: linear-gradient(135deg, #28a745, #d4edda); /* Gradient for Beginner Tutorial */
  }
  
  .screenMiddle svg {
      width: 22px !important;
  }

  .direct2 {
    height: 5%; /* Converted height to percentage */
    width: 80%; /* Already in percentage */
    background: linear-gradient(135deg, #29e6c9, #0066ff); /* Gradient background */
    /* border-radius: px; */
    /* border-color: #fff; */
    border-radius: 8px;
    border:none;
    cursor: "pointer";
    transition: 'background 0.1s ease';
     box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.3);
     /* padding:1em; */
  
  }
  
  .icon {
    margin-right: 0.5%; /* Converted margin-right to percentage */
    color: #ffffff; /* Set icon color to white */
  }
  
  .icon1 {
    margin-right: 0.5%; /* Converted margin-right to percentage */
    color: #000; /* Set icon color to white */
  }
  

  .screenMiddle3 {
    margin: 10px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; 
    gap: 0px;
  }
  
  .cardPlan {
    position: relative; 
    flex: 1 1 calc(47% - 10px); 
    border-radius: 8px;
    box-sizing: border-box; 
    border: none;
    background: linear-gradient(90deg, #FF5733, #C70039);
    margin: 2px;
    transition: background 0.3s ease;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 2px;
    margin-left: 8px;
  }
  
  .locked {
    background: #6a6a6a; /* Background color for cards with the lock icon */
  }
  .lockIcon {
    position: absolute;
    top: 50%; 
    left: 50%;
    transform: translate(-50%, -50%); 
    /* background: rgba(0, 0, 0, 0.5);  */
    border-radius: 50%; 
    padding: 10px; /* Padding around the icon */
    color: #fff; /* White color for the icon */
    font-size: 24px; /* Size of the icon */
  }
  
  /* .card img {
  
    height: auto;
  } */
  
  .cardImage {
    width:100%; /* Image should scale to fit the card */
    height: auto;
    border-radius: 10px;
    object-fit:cover;
    aspect-ratio:2/1.5;
  }
  
  
  .userListH {
    display: flex;
    flex-direction: column;
    width: 98%;
margin: 5px;
    padding: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
    border-radius: 8px;
  }
  
  .cardH {
    background-color: #fff;
 justify-content: space-between;
    margin: 5px 0;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(22, 43, 202, 0.5);
    flex-direction: row;
    display: flex;
    padding: 10px;
    
  }
  
  .topUserH {
    border: 2px solid gold;
  }
  
  .usernameH {
    font-size: 18px;
    font-weight: bold;
  }
  
  .earningH {
    font-size: 16px;
    color: #888;
  }
  .countryH{
    font-size: 16px;
    color: #000;
  }
  
  .topUserTagH {
    margin-top: 5px;
    font-size: 14px;
    color: red;
  }

  
  .avatar-imgH {
    width: 60px; /* Set desired image width */
    height: 60px; /* Set desired image height */
    border-radius: 50%; /* Optional: make the image round */
  }
  
  
  