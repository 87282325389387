
.profile-container1 {
  /* display: flex; */
  flex-direction: column;
  /* height: 100vh; */
}
.cardp{
  max-width: 100%;
  max-height: 830px;
  margin: 1rem ;
  padding: 1rem;
  border-radius: 15px;
  justify-content: "center";
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.5);
  margin-top: 80px;
  margin-bottom: 80px;
}

@media only screen and (max-width:575px) {
  .cardp {
      max-height: 770px;
  }
}

/* First part: Profile Info */
.profile-info {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: #f0f0f0;
  /* padding: 20px; */
  height: 25vh;
  margin-top: 10px;
  margin-bottom: 10px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.5);
  border-radius: 15px;
}

.profile-photo-div {
  width: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.profile-photo {
  width: 200px; /* Adjust the size as needed */
  height: 200px;
  border-radius: 50%;
  object-fit: cover;
}

.profile-details {
  margin-left: 20px;
}
.profile-details h2,
.profile-details p {
  margin: 0;
}
.level {
  display: inline-block;
  margin-top: 10px;
  padding: 5px 15px;
  border-radius: 20px;
  background-image: linear-gradient(to right, #cd7f32, #b87333); /* Bronze gradient */
  color: white;
  font-weight: bold;
  margin-top: 15px;
  text-transform: uppercase;
}
.depositp {
  height: 40px; /* Converted height to percentage */
  width: 90px; /* Already in percentage */
  background: linear-gradient(135deg, #29e6c9, #0066ff); /* Gradient background */
  margin-right: 1%; 
  border-radius: 5px;
  border:none;
  cursor: "pointer";
  transition: 'background 0.3s ease';
   box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.5);
}

/* Second part: Balance and buttons */
.balance-section {
  height: 16vh;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  background-color: #e0e0e0;
  padding: 20px 20px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.5);
  margin-top: 20px;
}


@media screen and (max-width: 575px) { 
  .balance-section {
    height: 20vh;
    display: flex;
    padding: 20px 20px;
  }
  .cardProfile {
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color: #f5f5f5; */
    background:linear-gradient(90deg, #FF5733, #C70039);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.5);
    cursor: pointer;
    text-align: center;
    transition: background-color 0.2s ease;
    border-radius: 5px;
    font-size: 12px;
  }
}

.Avail-bal {
  /* width: 100%; */
  display: flex;
  flex-direction: row;
}

.balance-buttons {
  display: flex;
  justify-content: space-between;
  /* width: 100%; */
}

.balance-buttons button {
  flex: 1;
  margin: 0 10px;
  padding: 10px;
  cursor: pointer;
}

/* Third part: Cards section */
.cards-section-profile {
  height: auto; /* Adjust height based on content */
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  padding: 20px;
  background-color: #fff;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.5);
}

.cardProfile {
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: #f5f5f5; */
  background:linear-gradient(90deg, #FF5733, #C70039);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.5);
  cursor: pointer;
  text-align: center;
  transition: background-color 0.2s ease;
  border-radius: 5px;
}

.cardProfile:hover {
  background-color: #e0e0e0;
}
.card-margin-bottom{
  margin-bottom: 400px;
}


.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 5px;
  position: relative;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 16px;
  cursor: pointer;
}

.image-gallery {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
}

.modal-image {
  width: 100%;
  cursor: pointer;
  border: 2px solid transparent;
}

.modal-image:hover {
  border-color: #007bff; /* Change border color on hover */
}


/* Responsive styles */
@media (max-width: 768px) {
  .cards-section-profile {
    grid-template-columns: repeat(3, 1fr); /* Keep 3 cards per row */
  }
}

@media (max-width: 480px) {
  .cards-section-profile {
    grid-template-columns: repeat(3, 1fr); /* Keep 3 cards per row */

  }

  .testAvalbalAmount{
    font-size: 18px; 
    margin-left: 20px;
  }
  .textAvailbal{
    font-size: 18px;
  }

  .cardProfile {
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color: #f5f5f5; */
    background:linear-gradient(90deg, #FF5733, #C70039);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.5);
    cursor: pointer;
    text-align: center;
    transition: background-color 0.2s ease;
    border-radius: 5px;
    font-size: 12px;
  }


  .profile-photo {
    width: 80px; /* Smaller profile photo on mobile */
    height: 80px;
  }



}

@media (max-width: 768px) {
  .cards-section-profile {
    grid-template-columns: repeat(3, 1fr); /* Keep 3 cards per row */
  }
  .testAvalbalAmount{
    font-size: 18px; 
    margin-left: 20px;
  }
  .textAvailbal{
    font-size: 18px;
  }
}

@media (max-width: 399px) {
  .cards-section-profile {
    grid-template-columns: repeat(3, 1fr); /* Keep 3 cards per row */

  }
  .testAvalbalAmount{
    font-size: 18px; 
    margin-left: 20px;
  }
  .textAvailbal{
    font-size: 18px;
  }

  .cardProfile {
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color: #f5f5f5; */
    background:linear-gradient(90deg, #FF5733, #C70039);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.5);
    cursor: pointer;
    text-align: center;
    transition: background-color 0.2s ease;
    border-radius: 5px;
    font-size: 12px;
  }


  .profile-photo {
    width: 80px; /* Smaller profile photo on mobile */
    height: 80px;
  }

  

}

