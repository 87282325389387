/* deposit.css */

.containerDeposit {
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
  z-index: 0;
  /* background-color: #CDF1E5; */
  display: flex; /* Added to center contents */
  flex-direction: column; /* Added to stack elements vertically */
}

.bodyDepsoit {

  max-width: 500px;
  margin: 2rem auto;
  padding: 2rem;
  /* border: 1px solid #ddd; */
  border-radius: 15px;
  justify-content: "center";
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.5);
  margin-top: 100px;

}
.bodyDepsoitY {

  /* max-width: 700px; */
  /* margin: 2rem auto; */
  padding: 0.5rem;
  /* border: 1px solid #ddd; */
  width: 95%;
  border-radius: 15px;
  justify-content: "center";
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.5);
  margin-top: 100px;


}

.image-usdt {
  width: 15%;
  height: 15%;
  border-radius: 10px;
  border-width: 5px;
  margin: 5px;
}

.button-next {
  background: linear-gradient(90deg, #FF5733, #C70039);
  color: #fff;
  padding: 10px 0;
  width: 60%;
  border: none;
  border-radius: 8px;
  font-size: 16px;
  cursor: pointer;
  /* transition: background 0.3s ease; */
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.5);
}

.modal-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 90%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}

.modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  max-width: 100%;
  max-height: 60%;
  overflow-y: auto;
  margin-bottom: 100px;
}

.close-button-container {
  width: 100%;
  padding: 10px;
  text-align: right;
}

.close-button {
  background-color: #1B334D;
  width: 25%;
  height: 20%;
  border-radius: 10px;
}


/* Add this CSS to your stylesheet */
.radio-button {
    position: relative;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    border: 2px solid #fff;
    background: linear-gradient(135deg, #ff6f61, #de6a9e);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin: 20px;
    transition: background 0.3s ease;
  }
  
  .radio-button.selected {
    background: linear-gradient(135deg, #6a11cb, #2575fc);
  }
  
  .checkmark {
    position: absolute;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: black;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: opacity 0.3s ease;
  }
  
  .radio-button.selected .checkmark {
    opacity: 1;
  }
  /* Hide the default radio button */
.custom-radio input[type="radio"] {
    display: none;
  }
  
  /* Style the custom radio button */
  .custom-radio .radio-label {
    position: relative;
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    margin-right: 10px;
  }
  
  /* Radio button appearance */
  .custom-radio .radio-label .radio-btn {
    width: 24px; /* Size of the radio button */
    height: 24px;
    border-radius: 50%;
    border: 2px solid #000; /* Border color */
    background: #fff; /* Background color */
    display: flex;
    align-items: center;
    justify-content: center;
    /* transition: background 0.3s ease; */
  }
  
  /* Checkmark appearance */
  .custom-radio .radio-label .radio-btn::after {
    content: '';
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: black;
    opacity: 0;
    transition: opacity 0.3s ease;
  }
  
  /* When the radio button is selected */
  .custom-radio input[type="radio"]:checked + .radio-label .radio-btn {
    background: #000; /* Background color when selected */
  }
  
  .custom-radio input[type="radio"]:checked + .radio-label .radio-btn::after {
    opacity: 1; /* Show the checkmark when selected */
  }
  
  