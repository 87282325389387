.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.card-container {
  max-width: 1200px;
  max-height: 1200px;
  margin: 10px auto; 
  
}

.card-body {
  width: 100%; 
  padding: 5px !important;
}

.table-container {
  max-height: 420px; /* Max height for the table */
  max-width: 100%; /* Full width */
  overflow-y: auto; /* Enable vertical scrolling */
}



th {
  position: sticky; /* Allows the header to stick */
  top: 0; /* Position it at the top */
  z-index: 1; /* Ensure it's above the table body */
}
.notification-badge-admin-support {
  background-color: red;
  color: white;
  border-radius: 50%;
  padding: 2px 6px;
  margin-left: 5px;
  font-size: 12px;
}

.main_dsborad_cntenT {
  width: calc(100% - 20%);
  margin-left: 72px;
}

.sidebarAdmin {
  background: linear-gradient(90deg, #FF5733, #C70039);
  height: 150vh;
  position: fixed;
  z-index: 9;
  top: 0;
  left: 0;
}

.custom-spinner-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 200px; /* Adjust height as needed */
}

.custom-spinner {
  width: 40px;
  height: 40px;
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-top-color: #3498db;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.custom-spinner-container p {
  margin-top: 10px;
  font-size: 16px;
  color: #555;
}
