.company-detail {
    /* padding: 20px; */
    max-width: 900px;
    margin: auto;
    font-family: Arial, sans-serif;
  }
  
  h1 {
    color: #333;
    margin-bottom: 20px;
    text-align: center;
  }
  
  .detail-card {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    margin-bottom: 20px;
  }
  
  .detail-card h2 {
    color: #444;
    margin-bottom: 10px;
  }
  
  .detail-card h3 {
    color: #555;
    margin-bottom: 5px;
  }
  
  .detail-card p {
    line-height: 1.6;
    color: #555;
  }
  
  ul {
    list-style-type: none;
    padding: 0;
  }
  
  ul li {
    margin-bottom: 20px;
  }
  
  .card {
    background-color: #fff;
    border-radius: 8px;
    /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
    padding: 20px;
    margin-bottom: 20px;
    margin-top: 10%;
    margin-left: 8px;
  }