.supports-container {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: white;
  overflow: hidden;
}

.support-container {
  max-width: 370px;
  margin: 0.5rem;
  padding: 0.5rem;
  border: 1px solid #ddd;
  border-radius: 15px;
  justify-content: center; /* Fixed the issue */
  background-color: #f0f0f0;
  box-shadow: 0px 4px 12px rgba(64, 123, 250, 0.5);
  margin-top: 70px;
}

.chat-area {
  display: flex;
  flex-direction: column; /* Stack messages vertically */
  padding: 10px;
  overflow-y: auto; /* Enable vertical scrolling for the entire chat area */
  max-height: 400px; /* Adjust based on your layout */
}

/* Date group should no longer scroll independently */
.date-group {
  display: flex;
  flex-direction: column; /* Stack messages vertically */
  padding: 10px;
}

.date {
  text-align: center; /* Center the date */
  font-weight: bold;
  color: #999; /* Light color for date */
  margin-bottom: 1em;
}

.message-content {
  display: flex;
  flex-direction: row;
  gap: 15px;
}

.message-time {
  font-size: 0.8em;
  color: #ccc;
}

.uploader__placeholder {
  min-height: 60px;
  min-width: 80px;
  margin-right: 8px;
  border-radius: 5px;
}

.message {
  max-width: 90%;
  margin-bottom: 10px;
  padding: 5px;
  color: #000;
}

.user-message {
  background-color: #fff;
  align-self: flex-start;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.system-message {
  background-color: #2196f3;
  align-self: flex-end;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.input-containerC {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: white;
  box-sizing: border-box;
  box-shadow: 0px -4px 12px rgba(35, 107, 179, 0.5);
  z-index: 1000;
}

.input-box {
  flex: 1;
  background-color: white;
  border: 1px solid #ec8e22;
  border-radius: 10px;
  padding: 10px;
  margin-right: 0px;
}

.send-button {
  background: linear-gradient(45deg, #f39c12, #e74c3c);
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
}

@media (max-width: 768px) {
  .input-container {
    padding: 5px;
  }

  .input-box {
    font-size: 14px;
  }

  .send-button {
    padding: 8px 16px;
    font-size: 14px;
  }
}



/* .supports-container {
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: white;
    overflow: hidden;
  }
  .support-container {
    max-width: 370px;
    margin: 1rem;
    padding: 0.5rem;
    border: 1px solid #ddd;
    border-radius: 15px;
    justify-content: "center";
  background-color: #f0f0f0;
    box-shadow: 0px 4px 12px rgba(64, 123, 250, 0.5);
  }
 
  .chat-area {
    margin-top: 50px;
    flex: 1;
    padding: 20px;
    overflow-y: scroll;
    max-height: calc(100vh - 150px); 
  }


  .message {
    max-width: 70%;
    margin-bottom: 10px;
    padding: 10px;
    border-radius: 10px;
    color: white;
    word-wrap: break-word;
  }
  

  .user-message {
    background-color: gray;
    align-self: flex-start;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  .system-message {
    background-color: #1b334d;
    align-self: flex-end;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }

.input-container {
    display: flex;
    align-items: center;
    margin: 10px;
    padding: 10px;
    position: fixed;
    bottom: 5%;
    width: 100%;
    background-color: white;
    box-sizing: border-box;
    box-shadow: 0px 4px 12px rgba(64, 123, 250, 0.5);
  }
  

  .input-box {
    flex: 1;
    background-color: white;
    border: 1px solid #ec8e22;
    border-radius: 10px;
    padding: 10px;
    margin-right: 10px;
  }
  
 
  .send-button {
    background: linear-gradient(45deg, #f39c12, #e74c3c);
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
  }
  

  .gallery-icon {
    font-size: 24px;
    margin-right: 10px;
    cursor: pointer;
    color: #1b334d;
  }
   */
  