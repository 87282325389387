.notifications-page {
    /* padding: 20px; */
    max-width: 600px;
    margin: 0 auto;
  }
  
  .notifications-page h2 {
    text-align: center;
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  .notifications-list {
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin-top: 70px;
  }
  
  .notification-item {
    padding: 15px;
    border-radius: 8px;
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    position: relative;
  }
  
  .notification-message {
    font-size: 16px;
    margin: 0 0 5px;
  }
  
  .notification-time {
    font-size: 14px;
    color: #888;
  
  }
  