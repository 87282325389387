.containerWithdrawal {
  /* height: 100%; */
  /* width: 100%; */
  align-items: center;
  justify-content: center;
  z-index: 0;


  /* display: flex; */
  /* flex-direction: column; */
}

.bodyWithdral {
max-width: 500px;
  margin: 1rem auto;
  padding: 0.5rem;
  /* border: 1px solid #ddd; */
  border-radius: 15px;
  justify-content: "center";
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.5);
  margin-top: 50px;
  margin-bottom: 100px;

}

.transaction-Withdrawal{
  margin-top: 100px;
  height: 85vh;
  width: 100%;
  /* width: 50vh; */
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  /* background-color: #e0e0e0; */
  padding: 10px 10px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.5);
  margin-top: 5px;
  border-radius: 10px;
}
  
  .buttonw {
    background: linear-gradient(90deg, #EC8E22, #FFC107);
    border: none;
    border-radius: 5px;
    padding: 10px;
    width: 90%;
    cursor: pointer;
    outline: none;
  }
  
  .buttonw h6 {
    color: #fff;
    font-size: 12px;
    margin: 0;
  }
  
  .title {
    width: 100%;
    height: 50px;
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    color: black;
    font-weight: bold;
  }
  
  .amount-input-section {
    flex-direction: row;
    border-radius: 50px;
    border-width: 2px;
    border-color: #EC8E22;
    width: 70%;
    display: flex;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
    background-color: #fff;
  }
  
  .amount-input-section .input-wrapper {
    width: 15%;
    margin-left: 5px;
    margin-top: 3px;
  }
  
  .amount-input-section input {
    height: 40px;
    width: 100%;
    padding: 10px;
    color: #000;
    border: none;
    outline: none;
  }
  
  .reduced-amount {
    text-align: center;
    margin-top: 10px;
  }
  
  .reduced-amount span {
    font-weight: bold;
    color: #EC8E22;
  }
  .no-upi-section {
margin-top: 30px;
  }
  .upi-section {
    max-width: 600px;
    padding: 2rem;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center; /* Center items vertically */
    align-items: center; /* Center items horizontally */
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.5);
    margin-top: 100px;
    background-color: #fff; 
  }
  
  .withdrawal-request-button {
    flex-direction: row;
    width: 50%;
    margin-top: 20px;
    justify-content: center;
    display: flex;
  }
  
  .withdrawal-request-button button {
    background: linear-gradient(90deg, #FF5733, #C70039);
    padding: 10px 20px;
    border-radius: 50px;
    width: 35%;
    color: #fff;
    border: none;
    cursor: pointer;
    outline: none;
  }
  
  .terms-conditions {
    max-width: 600px;
    padding: 1rem;
    border-radius: 15px;
    background-color: #F3F4F6;
    /* padding: 15px; */
    border-radius: 10px;
    margin-top: 10px; 
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.5);
  }
  
  .terms-conditions p {
    color: #1B334D;
    font-weight: bold;
    font-size: 18px;
    margin-bottom: 10px;
  }
  
  .terms-conditions ul {
    color: #1B334D;
    list-style-type: none;
    padding-left: 0;
  }
  
  .terms-conditions li {
    margin-bottom: 5px;
  }
  
  .wallet-address-container {
    max-width: 600px;
    padding: 2rem;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center; /* Center items vertically */
    align-items: center; /* Center items horizontally */
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.5);
    margin-top: 10px;
    background-color: #fff; 
  }
  
  .wallet-address {
    width: 100%;
    height: 50px;
    margin-top: 5px;
    justify-content: center;
    align-items: center;
    display: flex;
    font-size: 20px;
    color: black;
    font-weight: bold;
  }
  
  .wallet-input {
    position: relative;
    width: 100%;
    border-radius: 10px;
    border-width: 2px;
    border-color: #000;
    border-style: solid;
    padding: 5px;
    background-color: #fff;
    display: flex;
    align-items: center;
  }
  
  .wallet-input input {
    height: 40px;
    width: 80%;
    padding: 10px 40px 10px 10px;
    color: #000;
    border: none;
    outline: none;
    border-radius: 50px;
  }
  
  .qr-code-button {
    width: 15%;
    background-color: #fff;
    border: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .divider {
    height: 1px;
    background-color: #8EB4A7;
    width: 100%;
    margin-top: 20px;
  }
  
  .saved-address {
    margin-top: 20px;
    margin-left: 30px;
    color: black;
    font-size: 20px;
    font-weight: bold;
  }
  
  .saved-address-list {
    margin-top: 5px;
    height: 300px;
    padding: 0px;
  }
  
  