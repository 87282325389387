.profile-container {
    padding: 20px;
    background-color: #f5f5f5;
  }
  
  .profile-card {
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 15px;
  }
  
  .profile-item {
    display: flex;
    justify-content: space-between;
    padding: 10px 0;
    border-bottom: 1px solid #f0f0f0;
  }
  
  .profile-item:last-child {
    border-bottom: none;
  }
  
  .profile-label {
    font-size: 16px;
    color: #888;
  }
  
  .profile-value {
    font-size: 16px;
    font-weight: bold;
    color: #000;
  }
  
  .clickable {
    cursor: pointer;
  }
  
  .clickable:hover {
    background-color: #f0f0f0;
  }
  
  .profile-avatar {
    display: flex;
    align-items: center;
  }
  
  .avatar-img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }
  .logout-button-container {
    display: flex;         /* Use flexbox */
    justify-content: center; /* Center the button */
    width: 100%;          /* Take full width */
    margin-top: 20px;     /* Space above the button */
  }
  
  .logout-button {
    background-color: #f44336; /* Red background */
    color: white; /* White text */
    border: none; /* No border */
    padding: 10px 20px; /* Padding */
    border-radius: 5px; /* Rounded corners */
    cursor: pointer; /* Pointer cursor on hover */
    font-size: 16px; /* Font size */
    margin-top: 20px; /* Space above the button */
  }
  
  .logout-button:hover {
    background-color: #d32f2f; /* Darker red on hover */
  }
  